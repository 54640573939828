var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var _ResolveValue_answers, _ResolveValue_cachedAnswers, _ResolveValue_segment, _ResolveValue_reusableResolvers, _ResolveValue_computedValues;
import { v4 as uuidv4 } from 'uuid';
import { CachedQueryLatestSourcedDataType, ConditionType, Operator, ResolverType, ReusableResolverType, TypedResolverType, ValuesType, } from '@/types';
import { ResolveDate, convertTimeToMinutes, decimalSeparator, getLocalStorage, getMapByKey, hasNullValues, parseTime, timeZoneName, tryGetValue, } from '.';
export class ResolveValue extends ResolveDate {
    constructor(answers, cachedAnswers, segment, reusableResolvers, computedValues) {
        super();
        _ResolveValue_answers.set(this, void 0);
        _ResolveValue_cachedAnswers.set(this, void 0);
        _ResolveValue_segment.set(this, void 0);
        _ResolveValue_reusableResolvers.set(this, void 0);
        _ResolveValue_computedValues.set(this, void 0);
        this.currentIterationOfQuery = 0;
        __classPrivateFieldSet(this, _ResolveValue_answers, answers, "f");
        __classPrivateFieldSet(this, _ResolveValue_cachedAnswers, cachedAnswers, "f");
        __classPrivateFieldSet(this, _ResolveValue_segment, segment, "f");
        __classPrivateFieldSet(this, _ResolveValue_reusableResolvers, reusableResolvers, "f");
        __classPrivateFieldSet(this, _ResolveValue_computedValues, computedValues, "f");
    }
    resolveAllAnswers(bKey, sKey, aKey) {
        return __classPrivateFieldGet(this, _ResolveValue_answers, "f")
            .filter((a) => a.answered &&
            a.block_key === bKey &&
            a.page_key.scene_key === sKey &&
            (aKey ? a.answer_key === aKey : true))
            .sort((a, b) => { var _a, _b; return ((_a = a === null || a === void 0 ? void 0 : a.meta) === null || _a === void 0 ? void 0 : _a.order) - ((_b = b === null || b === void 0 ? void 0 : b.meta) === null || _b === void 0 ? void 0 : _b.order); });
    }
    resolveAnswer(bKey, page, aKey, options = {}, expectedType) {
        var _a;
        const { returnKey = 'typed_value.value', shouldCrash = true } = options;
        const { repeat_index, repeat_key, scene_key } = page;
        const answer = __classPrivateFieldGet(this, _ResolveValue_answers, "f").find((a) => a.block_key === bKey &&
            a.page_key.scene_key === scene_key &&
            (aKey ? a.answer_key === aKey : true) &&
            (repeat_key ? a.page_key.repeat_key === repeat_key : true) &&
            (typeof repeat_index === 'number' ? a.page_key.repeat_index === repeat_index : true));
        if (shouldCrash && !answer && __classPrivateFieldGet(this, _ResolveValue_answers, "f").length > 0) {
            this.resolveCrash(`Resolve Answer - Answer does not exist for: block_key: ${bKey}, scene_key: ${scene_key}, answer_key: ${aKey}}}`);
        }
        // @ts-ignore
        const value = returnKey.split('.').reduce((acc, key) => acc && acc[key], answer);
        if (expectedType && expectedType !== (answer === null || answer === void 0 ? void 0 : answer.typed_value.type)) {
            this.resolveCrash(`Expected answer ${aKey} to be of type ${expectedType}, but instead received ${(_a = answer === null || answer === void 0 ? void 0 : answer.typed_value) === null || _a === void 0 ? void 0 : _a.type}`);
        }
        return value;
    }
    resolveAnswerCachedQuery(key) {
        var _a;
        const answer = this.getAnswersCachedMap.get(key);
        return (_a = answer === null || answer === void 0 ? void 0 : answer.typed_value) === null || _a === void 0 ? void 0 : _a.value;
    }
    resolveComputedAnswer(computed) {
        var _a;
        const page = this.resolvePage(computed.page_resolver);
        const { resolver } = (_a = __classPrivateFieldGet(this, _ResolveValue_computedValues, "f").get(page.scene_key)) === null || _a === void 0 ? void 0 : _a.get(computed.computed_value_key);
        return this.resolveComputedValue(resolver);
    }
    resolveComputedValue(resolver) {
        const typeComputedMap = {
            [TypedResolverType.BOOLEAN]: this.resolveBoolean.bind(this),
            [TypedResolverType.DECIMAL]: this.resolveDecimal.bind(this),
            [TypedResolverType.INTEGER]: this.resolveInteger.bind(this),
            [TypedResolverType.STRING]: this.resolveString.bind(this),
            [TypedResolverType.ZONED_DATETIME]: this.resolveZonedDateTime.bind(this),
            [TypedResolverType.DATE]: (resolver) => {
                return this.formatDateLocalized(this.resolveDate.bind(this)(resolver)).date_stamp;
            }
        };
        const resolverComputed = typeComputedMap[resolver.type];
        return tryGetValue(() => resolverComputed(resolver.value));
    }
    resolveCrash(message) {
        throw new Error(message);
    }
    resolveCurrentQueuePage() {
        const localData = getLocalStorage();
        const page = JSON.parse((localData === null || localData === void 0 ? void 0 : localData.current_queue_page) || '{}');
        return Object.assign({ repeat_index: null, repeat_key: null }, page);
    }
    resolveCCQManyGetMatchingValue(resolver) {
        const { condition, result_cache_key, return_key } = resolver;
        const result = __classPrivateFieldGet(this, _ResolveValue_cachedAnswers, "f").custom.many[result_cache_key];
        if (!result)
            this.resolveCrash('CCQ Get Many Matching Entry - No result cache found');
        const matchedRow = result.find((r) => {
            const conditionKey = this.resolveBoolean(condition);
            if (conditionKey)
                return r;
        });
        if (!matchedRow)
            this.resolveCrash('CCQ Get Many Matching Entry - No matching row found');
        const answer = matchedRow.find((x) => x.key === this.resolveString(return_key));
        if (!answer)
            this.resolveCrash('CCQ Get Many Matching Entry - No matching entry found');
        return answer.typed_value.value;
    }
    resolveCCQManyGetCurrentIterationAnswer(resolver) {
        var _a;
        const { result_cache_key, value_key } = resolver;
        const result = __classPrivateFieldGet(this, _ResolveValue_cachedAnswers, "f").custom.many[result_cache_key];
        const resultRow = result[this.currentIterationOfQuery];
        const answer = (_a = resultRow.find((x) => x.key === value_key)) === null || _a === void 0 ? void 0 : _a.typed_value;
        return answer === null || answer === void 0 ? void 0 : answer.value;
    }
    resolveCCQSingleAnswer(resolver, type) {
        var _a;
        const { key, result_cache_key } = resolver;
        const result = __classPrivateFieldGet(this, _ResolveValue_cachedAnswers, "f").custom.single[result_cache_key];
        const resultKey = this.resolveString(key);
        const answer = (_a = result.find((x) => x.key === resultKey)) === null || _a === void 0 ? void 0 : _a.typed_value;
        if (answer.type !== type)
            this.resolveCrash('CCQ Get Single Value - type does not match');
        return answer === null || answer === void 0 ? void 0 : answer.value;
    }
    resolveLatestSourcedData(resolver, fallback, valueType) {
        const key = this.resolveString(resolver);
        const value = this.getAnswersCachedMap.get(key);
        if (value && value.typed_value.type !== valueType) {
            this.resolveCrash('Cached Query - Value type does not match');
        }
        return this.resolveAnswerCachedQuery(key) || fallback;
    }
    resolveNullFallbackValue(resolver) {
        const { fallback, type, value } = resolver;
        const resolverMap = {
            [ResolverType.BOOLEAN_FALLBACK]: this.resolveBoolean.bind(this),
            [ResolverType.DATE_FALLBACK]: this.resolveDate.bind(this),
            [ResolverType.DECIMAL_FALLBACK]: this.resolveDecimal.bind(this),
            [ResolverType.INTEGER_FALLBACK]: this.resolveInteger.bind(this),
            [ResolverType.STRING_FALLBACK]: this.resolveString.bind(this),
            [ResolverType.TIME_FALLBACK]: this.resolveTime.bind(this),
            [ResolverType.ZONED_DATETIME_FALLBACK]: this.resolveZonedDateTime.bind(this),
        };
        const resolverFunction = resolverMap[type];
        try {
            const resolvedValue = resolverFunction(value);
            return (resolvedValue !== undefined && resolvedValue !== null
                ? resolvedValue
                : resolverFunction(fallback));
        }
        catch (_a) {
            return resolverFunction(fallback);
        }
    }
    resolveOperatorArithmetic(operator, leftValue, rightValue, options = {
        useArithmeticBitwise: true,
    }) {
        if (hasNullValues(leftValue, rightValue))
            return null;
        const operations = {
            [Operator.Arithmetic.ADDITION]: (a, b) => a + b,
            [Operator.Arithmetic.DIVISION]: (a, b) => {
                if (b === 0)
                    this.resolveCrash('Resolve Operator - Cannot divide by zero');
                return options.useArithmeticBitwise ? ~~(a / b) : a / b;
            },
            [Operator.Arithmetic.MODULO]: (a, b) => {
                if (b === 0)
                    this.resolveCrash('Resolve Operator - Modulo divisor cannot be zero');
                return a % b;
            },
            [Operator.Arithmetic.MULTIPLICATION]: (a, b) => a * b,
            [Operator.Arithmetic.SUBTRACTION]: (a, b) => a - b,
        };
        return operations[operator](leftValue, rightValue);
    }
    resolveOperatorBasic(operator, leftValue, rightValue) {
        if (hasNullValues(leftValue, rightValue))
            return null;
        const operations = {
            [Operator.Basic.EQUALS]: (a, b) => a === b,
            [Operator.Basic.GREATER_THAN]: (a, b) => a > b,
            [Operator.Basic.GREATER_THAN_OR_EQUALS]: (a, b) => a >= b,
            [Operator.Basic.LESS_THAN]: (a, b) => a < b,
            [Operator.Basic.LESS_THAN_OR_EQUALS]: (a, b) => a <= b,
            [Operator.Basic.NOT_EQUALS]: (a, b) => a !== b,
        };
        return operations[operator](leftValue, rightValue);
    }
    resolveResolvers(key, type) {
        const list = __classPrivateFieldGet(this, _ResolveValue_reusableResolvers, "f")[type];
        const lookupKey = this.resolveString(key);
        const found = list.find((x) => x.resolver_key === lookupKey);
        if (!found) {
            this.resolveCrash(`No item with key ${lookupKey} could be found in ${type}`);
        }
        return found.resolver;
    }
    resolveTimeUnits(units) {
        const timeUnits = {};
        for (const [key, value] of Object.entries(units)) {
            const resolvedValue = this.resolveInteger(value);
            timeUnits[key] = resolvedValue;
        }
        return timeUnits;
    }
    resolveAnswerFilter(resolver) {
        if (!resolver)
            return [];
        if (Array.isArray(resolver)) {
            return resolver.reduce((acc, curr) => [...acc, ...this.resolveAnswerFilter(curr)], []);
        }
        const { type } = resolver;
        switch (type) {
            case ResolverType.FILTER_ANSWER_KEY_MATCH: {
                return this.resolveStringList(resolver.answer_keys);
            }
            default: {
                this.resolveCrash(`${type} is an unsupported answer filter resolver`);
            }
        }
    }
    resolveBoolean(resolver = false) {
        var _a;
        if (typeof resolver === 'boolean')
            return resolver;
        const { type } = resolver;
        switch (type) {
            case ResolverType.LOOKUP: {
                const lookupResolver = this.resolveResolvers(resolver.resolver_key, ReusableResolverType.BOOLEAN);
                return this.resolveBoolean(lookupResolver);
            }
            case ResolverType.GET_COMPUTED_VALUE: {
                return this.resolveComputedAnswer(resolver);
            }
            case ResolverType.CCQ_MANY_GET_CURRENT_ITERATION_VALUE: {
                return this.resolveCCQManyGetCurrentIterationAnswer(resolver);
            }
            case ResolverType.CCQ_MANY_HAS_MATCHING: {
                const { condition, result_cache_key } = resolver;
                const result = __classPrivateFieldGet(this, _ResolveValue_cachedAnswers, "f").custom.many[result_cache_key];
                if (!result)
                    this.resolveCrash('CCQ Many Has Matching - result does not exist');
                return result.some((x, i) => {
                    this.currentIterationOfQuery = i;
                    return x.some(() => this.resolveBoolean(condition));
                });
            }
            case ResolverType.CCQ_MANY_GET_MATCHING_ENTRY: {
                const value = this.resolveCCQManyGetMatchingValue(resolver);
                if (typeof value !== 'boolean')
                    this.resolveCrash('CCQ Get Many Matching Entry - Type Mismatch');
                return value;
            }
            case ResolverType.BOOLEAN_HAS_VALUE: {
                const { resolver: subResolver } = resolver;
                const mapResolver = {
                    [TypedResolverType.BOOLEAN]: this.resolveBoolean,
                    [TypedResolverType.DATE]: this.resolveDate,
                    [TypedResolverType.DECIMAL]: this.resolveDecimal,
                    [TypedResolverType.INTEGER]: this.resolveInteger,
                    [TypedResolverType.STRING]: this.resolveString,
                    [TypedResolverType.ZONED_DATETIME]: this.resolveZonedDateTime,
                };
                const resolverFunction = mapResolver[subResolver.type].bind(this);
                const value = resolverFunction(subResolver.value);
                if ((subResolver.type === TypedResolverType.INTEGER ||
                    subResolver.type === TypedResolverType.DECIMAL) &&
                    isNaN(value)) {
                    return false;
                }
                return value !== undefined && value !== null;
            }
            case ResolverType.CCQ_SINGLE_GET_VALUE: {
                return this.resolveCCQSingleAnswer(resolver, ValuesType.BOOLEAN);
            }
            case CachedQueryLatestSourcedDataType.BOOLEAN_EXISTS: {
                const key = this.resolveString(resolver.value_key);
                return this.getAnswersCachedMap.has(key);
            }
            case CachedQueryLatestSourcedDataType.BOOLEAN_VALUE: {
                const fallback = this.resolveBoolean(resolver.if_none);
                return this.resolveLatestSourcedData(resolver.value_key, fallback, ValuesType.BOOLEAN);
            }
            case CachedQueryLatestSourcedDataType.CLIENT_59_DATE_OVERLAP: {
                const { end_date_answer, start_date_answer, target_end_date_key, target_start_date_key } = resolver;
                const startDate = new Date(this.resolveZonedDateTime(start_date_answer));
                const endDate = new Date(this.resolveZonedDateTime(end_date_answer));
                const targetStartDate = new Date(this.resolveAnswerCachedQuery(target_start_date_key));
                const targetEndDate = new Date(this.resolveAnswerCachedQuery(target_end_date_key));
                const isOngoing = isNaN(targetEndDate.getTime()) && startDate >= targetStartDate;
                const isOverlapping = startDate <= targetEndDate && endDate >= targetStartDate;
                return isOngoing || isOverlapping;
            }
            case ConditionType.ALL_OF: {
                return resolver.conditions.every((c) => this.resolveBoolean(c));
            }
            case ConditionType.ALWAYS: {
                return true;
            }
            case ConditionType.ANY_OF: {
                return resolver.conditions.some((c) => this.resolveBoolean(c));
            }
            case ConditionType.DATE_COMPARISON: {
                const { left_hand_side, operator, right_hand_side } = resolver;
                const leftValue = this.resolveDate(left_hand_side).getTime();
                const rightValue = this.resolveDate(right_hand_side).getTime();
                return this.resolveOperatorBasic(operator, leftValue, rightValue);
            }
            case ConditionType.DECIMAL_COMPARISON: {
                const { left_hand_side, operator, right_hand_side } = resolver;
                const leftValue = this.resolveDecimal(left_hand_side);
                const rightValue = this.resolveDecimal(right_hand_side);
                return this.resolveOperatorBasic(operator, leftValue, rightValue);
            }
            case ConditionType.INTEGER_COMPARISON: {
                const { left_hand_side, operator, right_hand_side } = resolver;
                const leftValue = this.resolveInteger(left_hand_side);
                const rightValue = this.resolveInteger(right_hand_side);
                return this.resolveOperatorBasic(operator, leftValue, rightValue);
            }
            case ConditionType.STRING_COMPARISON: {
                const { left_hand_side, right_hand_side } = resolver;
                const leftValue = this.resolveString(left_hand_side);
                const rightValue = this.resolveString(right_hand_side);
                return leftValue === rightValue;
            }
            case ConditionType.IS_FALSE: {
                return !this.resolveBoolean(resolver.value);
            }
            case ConditionType.IS_TRUE: {
                const value = this.resolveBoolean(resolver.value);
                return Boolean(value);
            }
            case ConditionType.NEVER: {
                return false;
            }
            case ConditionType.TIME_COMPARISON: {
                const { left_hand_side, operator, right_hand_side } = resolver;
                const leftResolved = this.resolveTime(left_hand_side);
                const rightResolved = this.resolveTime(right_hand_side);
                const leftValue = convertTimeToMinutes(leftResolved);
                const rightValue = convertTimeToMinutes(rightResolved);
                return this.resolveOperatorBasic(operator, leftValue, rightValue);
            }
            case ConditionType.TIME_ZONE_EQUALITY: {
                const { left_hand_side, right_hand_side } = resolver;
                const leftValue = this.resolveTimezone(left_hand_side);
                const rightValue = this.resolveTimezone(right_hand_side);
                // case leftValue or rightValue is undefined - Answers not Exists
                // Validations will need to be refactored as it should only run when answered answer is valid
                if (typeof leftValue === 'undefined' || typeof rightValue === 'undefined') {
                    return true;
                }
                return leftValue === rightValue;
            }
            case ConditionType.ZONED_DATE_TIME_COMPARISON: {
                const { left_hand_side, operator, right_hand_side } = resolver;
                const leftValue = new Date(this.resolveZonedDateTime(left_hand_side)).getTime();
                const rightValue = new Date(this.resolveZonedDateTime(right_hand_side)).getTime();
                return this.resolveOperatorBasic(operator, leftValue, rightValue);
            }
            case ResolverType.ANSWER_VALUE: {
                const page = this.resolvePage(resolver.page_resolver);
                const resolved = this.resolveAnswer(resolver.block_key, page, resolver === null || resolver === void 0 ? void 0 : resolver.answer_key, {}, ValuesType.BOOLEAN);
                return resolved;
            }
            case ResolverType.BOOLEAN_ANSWER: {
                const resolved = this.resolveAnswer(resolver.block_key, resolver.page_key, null, {}, ValuesType.BOOLEAN);
                return resolved;
            }
            case ResolverType.BOOLEAN_CRASH: {
                this.resolveCrash(resolver.message);
                break;
            }
            case ResolverType.BOOLEAN_IS_ANSWERED:
            case ResolverType.BOOLEAN_IS_ANSWERED_V2: {
                const page = 'page_key' in resolver ? resolver.page_key : this.resolvePage(resolver.page_resolver);
                const answer = this.resolveAnswer(resolver.block_key, page, resolver.answer_key, {
                    shouldCrash: false,
                });
                return answer !== undefined;
            }
            case ResolverType.BOOLEAN_REPEAT_KEY_IS_ANSWER_KEY: {
                const { repeat_key } = this.resolveCurrentQueuePage();
                const answers = this.resolveAllAnswers(resolver.block_key, resolver.scene_key);
                return answers.some((a) => a.answer_key === repeat_key);
            }
            case ResolverType.BOOLEAN_REPEAT_KEY_IS_CONTAINED_IN: {
                const { repeat_key } = this.resolveCurrentQueuePage();
                return resolver.values.includes(repeat_key);
            }
            case ResolverType.BOOLEAN_SWITCH: {
                const condition = resolver.cases.find((c) => this.resolveBoolean(c.condition));
                return this.resolveBoolean((_a = condition === null || condition === void 0 ? void 0 : condition.value) !== null && _a !== void 0 ? _a : resolver.fallback);
            }
            case ResolverType.BOOLEAN_FALLBACK: {
                return this.resolveNullFallbackValue(resolver);
            }
            default: {
                this.resolveCrash(`${type} is an unsupported boolean resolver`);
            }
        }
    }
    resolveDate(resolver) {
        var _a;
        if (!resolver)
            return undefined;
        const { type } = resolver;
        switch (type) {
            case ResolverType.LOOKUP: {
                const lookupResolver = this.resolveResolvers(resolver.resolver_key, ReusableResolverType.DATE);
                return this.resolveDate(lookupResolver);
            }
            case ResolverType.ANSWER_VALUE: {
                const page = this.resolvePage(resolver.page_resolver);
                const resolved = this.resolveAnswer(resolver.block_key, page, resolver === null || resolver === void 0 ? void 0 : resolver.answer_key, {}, ValuesType.DATE);
                return this.formatDateToMidnight(resolved);
            }
            case ResolverType.DATE: {
                return this.formatDateToMidnight(resolver.value);
            }
            case ResolverType.DATE_CURRENT: {
                const now = new Date();
                return this.formatDateToMidnight(now);
            }
            case ResolverType.DATE_MODIFICATION: {
                const { type, value } = resolver, units = __rest(resolver, ["type", "value"]);
                const timeUnits = this.resolveTimeUnits(units);
                const date = this.resolveDate(value);
                return this.formatDateModified(date, timeUnits);
            }
            case ResolverType.DATE_SWITCH: {
                const condition = resolver.cases.find((c) => this.resolveBoolean(c.condition));
                return this.resolveDate((_a = condition === null || condition === void 0 ? void 0 : condition.value) !== null && _a !== void 0 ? _a : resolver.fallback);
            }
            case ResolverType.DATE_FALLBACK: {
                return this.resolveNullFallbackValue(resolver);
            }
            case ResolverType.GET_COMPUTED_VALUE: {
                const value = this.resolveComputedAnswer(resolver);
                return this.formatDateToMidnight(value);
            }
            default: {
                this.resolveCrash(`${type} is an unsupported date resolver`);
            }
        }
    }
    resolveDecimal(resolver) {
        var _a;
        if (!resolver)
            return undefined;
        const { type } = resolver;
        switch (type) {
            case ResolverType.LOOKUP: {
                const lookupResolver = this.resolveResolvers(resolver.resolver_key, ReusableResolverType.DECIMAL);
                return this.resolveDecimal(lookupResolver);
            }
            case ResolverType.GET_COMPUTED_VALUE: {
                return this.resolveComputedAnswer(resolver);
            }
            case ResolverType.CCQ_MANY_GET_CURRENT_ITERATION_VALUE: {
                return this.resolveCCQManyGetCurrentIterationAnswer(resolver);
            }
            case ResolverType.CCQ_MANY_GET_MATCHING_ENTRY: {
                const value = this.resolveCCQManyGetMatchingValue(resolver);
                if (typeof value !== 'number')
                    this.resolveCrash('CCQ Get Many Matching Entry - Type Mismatch');
                return value;
            }
            case ResolverType.CCQ_SINGLE_GET_VALUE: {
                return this.resolveCCQSingleAnswer(resolver, ValuesType.DECIMAL);
            }
            case ResolverType.ANSWER_VALUE: {
                const page = this.resolvePage(resolver.page_resolver);
                const resolved = this.resolveAnswer(resolver.block_key, page, resolver === null || resolver === void 0 ? void 0 : resolver.answer_key, {}, ValuesType.DECIMAL);
                return parseFloat(resolved === null || resolved === void 0 ? void 0 : resolved.parsed);
            }
            case ResolverType.DECIMAL: {
                return parseFloat(resolver.value);
            }
            case ResolverType.DECIMAL_ARITHMETIC_BASIC: {
                const { left_hand_side, operator, right_hand_side } = resolver;
                const leftValue = this.resolveDecimal(left_hand_side);
                const rightValue = this.resolveDecimal(right_hand_side);
                return this.resolveOperatorArithmetic(operator, leftValue, rightValue, {
                    useArithmeticBitwise: false,
                });
            }
            case ResolverType.DECIMAL_SWITCH: {
                const condition = resolver.cases.find((c) => this.resolveBoolean(c.condition));
                return this.resolveDecimal((_a = condition === null || condition === void 0 ? void 0 : condition.value) !== null && _a !== void 0 ? _a : resolver.fallback);
            }
            case ResolverType.DECIMAL_FALLBACK: {
                return this.resolveNullFallbackValue(resolver);
            }
            case ResolverType.INTEGER: {
                return resolver.value;
            }
            default: {
                this.resolveCrash(`${type} is an unsupported decimal resolver`);
            }
        }
    }
    resolveDependency(resolver) {
        if (!resolver)
            return [];
        if (Array.isArray(resolver)) {
            return resolver.reduce((acc, curr) => [...acc, ...this.resolveDependency(curr)], []);
        }
        const { type } = resolver;
        switch (type) {
            case ResolverType.DEPENDENCY_ANSWER: {
                const { block_key, page_resolver } = resolver;
                const page = this.resolvePage(page_resolver);
                return this.resolveAllAnswers(block_key, page.scene_key);
            }
            default: {
                this.resolveCrash(`${type} is an unsupported dependency resolver`);
            }
        }
    }
    resolveFloat(resolver) {
        const { type } = resolver;
        switch (type) {
            case ResolverType.LOOKUP: {
                const lookupResolver = this.resolveResolvers(resolver.resolver_key, ReusableResolverType.FLOAT);
                return this.resolveFloat(lookupResolver);
            }
            case ResolverType.ANSWER_VALUE: {
                const page = this.resolvePage(resolver.page_resolver);
                const resolved = this.resolveAnswer(resolver.block_key, page, resolver === null || resolver === void 0 ? void 0 : resolver.answer_key, {}, ValuesType.FLOAT);
                return resolved;
            }
            case ResolverType.FLOAT: {
                return resolver.value;
            }
            case ResolverType.FLOAT_ANSWER: {
                const resolved = this.resolveAnswer(resolver.block_key, resolver.page_key, null, {}, ValuesType.FLOAT);
                return resolved;
            }
            default: {
                this.resolveCrash(`${type} is an unsupported float resolver`);
            }
        }
    }
    resolveInteger(resolver) {
        var _a;
        const { type } = resolver;
        switch (type) {
            case ResolverType.LOOKUP: {
                const lookupResolver = this.resolveResolvers(resolver.resolver_key, ReusableResolverType.INTEGER);
                return this.resolveInteger(lookupResolver);
            }
            case ResolverType.GET_COMPUTED_VALUE: {
                return this.resolveComputedAnswer(resolver);
            }
            case ResolverType.CCQ_MANY_GET_CURRENT_ITERATION_VALUE: {
                return this.resolveCCQManyGetCurrentIterationAnswer(resolver);
            }
            case ResolverType.CCQ_MANY_GET_MATCHING_ENTRY: {
                const value = this.resolveCCQManyGetMatchingValue(resolver);
                if (typeof value !== 'number')
                    this.resolveCrash('CCQ Get Many Matching Entry - Type Mismatch');
                return value;
            }
            case ResolverType.CCQ_SINGLE_GET_VALUE: {
                return this.resolveCCQSingleAnswer(resolver, ValuesType.INTEGER);
            }
            case ResolverType.ANSWER_VALUE: {
                const page = this.resolvePage(resolver.page_resolver);
                const resolved = this.resolveAnswer(resolver.block_key, page, resolver === null || resolver === void 0 ? void 0 : resolver.answer_key, {}, ValuesType.INTEGER);
                return Number(resolved);
            }
            case CachedQueryLatestSourcedDataType.INTEGER_VALUE: {
                const fallback = this.resolveInteger(resolver.if_none);
                return this.resolveLatestSourcedData(resolver.value_key, fallback, ValuesType.INTEGER);
            }
            case ResolverType.INTEGER: {
                return resolver.value;
            }
            case ResolverType.INTEGER_ABSOLUTE: {
                return Math.abs(this.resolveInteger(resolver.input));
            }
            case ResolverType.INTEGER_ANSWER: {
                const resolved = this.resolveAnswer(resolver.block_key, resolver.page_key, null, {}, ValuesType.INTEGER);
                return Number(resolved);
            }
            case ResolverType.INTEGER_ANSWER_COUNT: {
                const page = this.resolvePage(resolver.page_resolver);
                return this.resolveAllAnswers(resolver.block_key, page.scene_key).length;
            }
            case ResolverType.INTEGER_ARITHMETIC_BASIC: {
                const { left_hand_side, operator, right_hand_side } = resolver;
                const leftValue = this.resolveInteger(left_hand_side);
                const rightValue = this.resolveInteger(right_hand_side);
                return this.resolveOperatorArithmetic(operator, leftValue, rightValue);
            }
            case ResolverType.INTEGER_CRASH: {
                this.resolveCrash(resolver.message);
                break;
            }
            case ResolverType.INTEGER_CURRENT_PAGE_REPEAT_INDEX: {
                const { repeat_index } = this.resolveCurrentQueuePage();
                return repeat_index;
            }
            case ResolverType.INTEGER_DAY_DIFFERENCE_DATE:
            case ResolverType.INTEGER_DAY_DIFFERENCE_ZONED_DATETIME: {
                const { left_hand_side, right_hand_side, type } = resolver;
                const resolvedLeftValue = type === ResolverType.INTEGER_DAY_DIFFERENCE_DATE
                    ? this.resolveDate(left_hand_side)
                    : this.resolveZonedDateTime(left_hand_side);
                const resolvedRightValue = type === ResolverType.INTEGER_DAY_DIFFERENCE_DATE
                    ? this.resolveDate(right_hand_side)
                    : this.resolveZonedDateTime(right_hand_side);
                const leftValue = new Date(resolvedLeftValue).getTime();
                const rightValue = new Date(resolvedRightValue).getTime();
                const millisecondsDifference = rightValue - leftValue;
                const millisecondsPerDay = 1000 * 60 * 60 * 24;
                return (Math.sign(millisecondsDifference) *
                    Math.floor(Math.abs(millisecondsDifference) / millisecondsPerDay));
            }
            case ResolverType.INTEGER_MAX_FROM_RANGE:
            case ResolverType.INTEGER_MIN_FROM_RANGE: {
                const answer = this.resolveRange(resolver.range);
                return type === ResolverType.INTEGER_MAX_FROM_RANGE ? answer === null || answer === void 0 ? void 0 : answer.max : answer === null || answer === void 0 ? void 0 : answer.min;
            }
            case ResolverType.INTEGER_SWITCH: {
                const condition = resolver.cases.find((c) => this.resolveBoolean(c.condition));
                return this.resolveInteger((_a = condition === null || condition === void 0 ? void 0 : condition.value) !== null && _a !== void 0 ? _a : resolver.fallback);
            }
            case ResolverType.INTEGER_FALLBACK: {
                const resolved = this.resolveNullFallbackValue(resolver);
                return isNaN(resolved) ? this.resolveInteger(resolver.fallback) : resolved;
            }
            default: {
                this.resolveCrash(`${type} is an unsupported integer resolver`);
            }
        }
    }
    resolvePage(resolver) {
        const { type } = resolver;
        switch (type) {
            case ResolverType.PAGE_BUILDER: {
                return {
                    repeat_index: null,
                    repeat_key: this.resolveString(resolver.repeat_key_builder),
                    scene_key: resolver.scene_key,
                };
            }
            case ResolverType.PAGE_CURRENT: {
                return this.resolveCurrentQueuePage();
            }
            default: {
                this.resolveCrash(`${type} is an unsupported page resolver`);
            }
        }
    }
    resolveRange(resolver) {
        const { type } = resolver;
        switch (type) {
            case ResolverType.LOOKUP: {
                const lookupResolver = this.resolveResolvers(resolver.resolver_key, ReusableResolverType.RANGE);
                return this.resolveRange(lookupResolver);
            }
            case ResolverType.ANSWER_VALUE: {
                const page = this.resolvePage(resolver.page_resolver);
                const resolved = this.resolveAnswer(resolver.block_key, page, resolver === null || resolver === void 0 ? void 0 : resolver.answer_key, {}, ValuesType.RANGE);
                return resolved;
            }
            case ResolverType.RANGE: {
                return resolver.value;
            }
            case ResolverType.RANGE_ANSWER: {
                const resolved = this.resolveAnswer(resolver.block_key, resolver.page_key, null, {}, ValuesType.RANGE);
                return resolved;
            }
            default: {
                this.resolveCrash(`${type} is an unsupported range resolver`);
            }
        }
    }
    resolveString(resolver) {
        var _a, _b, _c, _d;
        if (!resolver)
            return '';
        const { type } = resolver;
        switch (type) {
            case ResolverType.LOOKUP: {
                const lookupResolver = this.resolveResolvers(resolver.resolver_key, ReusableResolverType.STRING);
                return this.resolveString(lookupResolver);
            }
            case ResolverType.GET_COMPUTED_VALUE: {
                return this.resolveComputedAnswer(resolver);
            }
            case ResolverType.CCQ_MANY_GET_CURRENT_ITERATION_VALUE: {
                return this.resolveCCQManyGetCurrentIterationAnswer(resolver);
            }
            case ResolverType.CCQ_MANY_GET_MATCHING_ENTRY: {
                const value = this.resolveCCQManyGetMatchingValue(resolver);
                if (typeof value !== 'string')
                    this.resolveCrash('CCQ Get Many Matching Entry - Type Mismatch');
                return value;
            }
            case ResolverType.CCQ_SINGLE_GET_VALUE: {
                return this.resolveCCQSingleAnswer(resolver, ValuesType.STRING);
            }
            case ResolverType.ANSWER_VALUE: {
                const page = this.resolvePage(resolver.page_resolver);
                const resolved = this.resolveAnswer(resolver.block_key, page, resolver === null || resolver === void 0 ? void 0 : resolver.answer_key, {}, ValuesType.STRING);
                return resolved;
            }
            case CachedQueryLatestSourcedDataType.STRING_VALUE: {
                const fallback = this.resolveString(resolver.if_none);
                return this.resolveLatestSourcedData(resolver.value_key, fallback, ValuesType.STRING);
            }
            case ResolverType.STRING: {
                return resolver.value;
            }
            case ResolverType.STRING_ANSWER: {
                const resolved = this.resolveAnswer(resolver.block_key, resolver.page_key, null, {}, ValuesType.STRING);
                return resolved;
            }
            case ResolverType.STRING_ANSWER_LABEL: {
                const { answer_key, block_key, page_resolver } = resolver;
                const answerKey = this.resolveString(answer_key);
                const page = this.resolvePage(page_resolver);
                const blockHasMultipleAnswers = __classPrivateFieldGet(this, _ResolveValue_answers, "f").filter((a) => a.block_key === block_key && a.page_key.scene_key === page.scene_key).length > 1;
                if (blockHasMultipleAnswers && !answer_key) {
                    this.resolveCrash('answer_key is required for the answer_label resolver when used on a block with multiple answers');
                }
                const isAnswered = this.resolveAnswer(block_key, page, answerKey, {
                    returnKey: 'answered',
                });
                if (!isAnswered) {
                    return null;
                }
                const resolved = this.resolveAnswer(block_key, page, answerKey, {
                    returnKey: 'label',
                });
                return resolved;
            }
            case ResolverType.STRING_CONVERSION_FROM_DATE: {
                const value = this.resolveDate(resolver.input);
                return this.formatDateLocalized(value)[(_b = (_a = resolver.format) === null || _a === void 0 ? void 0 : _a.type) !== null && _b !== void 0 ? _b : 'date'];
            }
            case ResolverType.STRING_CONVERSION_FROM_DECIMAL: {
                const { input, num_decimal_places: decimal } = resolver;
                const value = this.resolveDecimal(input);
                if (isNaN(value)) {
                    this.resolveCrash('Decimal value was not a number.');
                }
                // @ts-ignore
                const formatted = Number(Math.round(value + 'e' + decimal) + 'e-' + decimal);
                return (_c = formatted === null || formatted === void 0 ? void 0 : formatted.toString()) === null || _c === void 0 ? void 0 : _c.replace(/\.|,/g, decimalSeparator);
            }
            case ResolverType.STRING_CONVERSION_FROM_INTEGER: {
                const value = this.resolveInteger(resolver.input);
                if (isNaN(value)) {
                    this.resolveCrash('Integer value was not a number.');
                }
                return String(value);
            }
            case ResolverType.STRING_CONVERSION_FROM_TIME: {
                const value = this.resolveTime(resolver.input);
                const { hours, minutes } = parseTime(value);
                const date = new Date();
                date.setHours(hours, minutes, 0, 0);
                return this.formatDateLocalized(date).time;
            }
            case ResolverType.STRING_CONVERSION_FROM_ZONED_DATE_TIME: {
                const zonedDateTime = new Date(this.removeTimezone(this.resolveZonedDateTime(resolver.input)));
                const formatter = this.formatDateLocalized(zonedDateTime);
                return formatter[resolver.format.type];
            }
            case ResolverType.STRING_CRASH: {
                this.resolveCrash(resolver.message);
                break;
            }
            case ResolverType.STRING_CURRENT_PAGE_REPEAT_KEY: {
                const { repeat_key } = this.resolveCurrentQueuePage();
                return repeat_key;
            }
            case ResolverType.STRING_INTERPOLATABLE: {
                const { display, interpolatable_values } = resolver;
                const result = interpolatable_values.reduce((interpolatedValue, { key, value }) => {
                    const resolved = this.resolveString(value);
                    return interpolatedValue.replace(`{{${key}}}`, resolved);
                }, display);
                return result;
            }
            case ResolverType.STRING_SEGMENT_ORIGINATOR: {
                const value = __classPrivateFieldGet(this, _ResolveValue_segment, "f").originator[resolver.property];
                if (!value)
                    this.resolveCrash('Segment originator does not match');
                return value;
            }
            case ResolverType.STRING_SWITCH: {
                const condition = resolver.cases.find((c) => this.resolveBoolean(c.condition));
                return this.resolveString((_d = condition === null || condition === void 0 ? void 0 : condition.value) !== null && _d !== void 0 ? _d : resolver.fallback);
            }
            case ResolverType.STRING_FALLBACK: {
                return this.resolveNullFallbackValue(resolver);
            }
            case ResolverType.STRING_UUID_V4: {
                return uuidv4();
            }
            default: {
                this.resolveCrash(`${type} is an unsupported string resolver`);
            }
        }
    }
    resolveStringList(resolver) {
        const { type } = resolver;
        switch (type) {
            case ResolverType.LOOKUP: {
                const lookupResolver = this.resolveResolvers(resolver.resolver_key, ReusableResolverType.STRING_LIST);
                return this.resolveStringList(lookupResolver);
            }
            case ResolverType.ANSWER_VALUE: {
                const page = this.resolvePage(resolver.page_resolver);
                return this.resolveAllAnswers(resolver.block_key, page.scene_key, resolver === null || resolver === void 0 ? void 0 : resolver.answer_key).map((a) => a.typed_value.value);
            }
            case ResolverType.LIST_STRING_ANSWERED_ANSWER_KEYS: {
                const page = this.resolvePage(resolver.page_resolver);
                return this.resolveAllAnswers(resolver.block_key, page.scene_key).map((a) => a.answer_key);
            }
            default: {
                this.resolveCrash(`${type} is an unsupported list string resolver`);
            }
        }
    }
    resolveTime(resolver) {
        var _a;
        if (!resolver)
            return '';
        const { type } = resolver;
        switch (type) {
            case ResolverType.LOOKUP: {
                const lookupResolver = this.resolveResolvers(resolver.resolver_key, ReusableResolverType.TIME);
                return this.resolveTime(lookupResolver);
            }
            case ResolverType.ANSWER_VALUE: {
                const page = this.resolvePage(resolver.page_resolver);
                const resolved = this.resolveAnswer(resolver.block_key, page, resolver === null || resolver === void 0 ? void 0 : resolver.answer_key, {}, ValuesType.TIME);
                return resolved;
            }
            case ResolverType.TIME: {
                return resolver.value;
            }
            case ResolverType.TIME_SWITCH: {
                const condition = resolver.cases.find((c) => this.resolveBoolean(c.condition));
                return this.resolveTime((_a = condition === null || condition === void 0 ? void 0 : condition.value) !== null && _a !== void 0 ? _a : resolver.fallback);
            }
            case ResolverType.TIME_FALLBACK: {
                return this.resolveNullFallbackValue(resolver);
            }
            default: {
                this.resolveCrash(`${type} is an unsupported time resolver`);
            }
        }
    }
    resolveTimezone(resolver) {
        if (!resolver)
            return '';
        const { type } = resolver;
        switch (type) {
            case ResolverType.LOOKUP: {
                const lookupResolver = this.resolveResolvers(resolver.resolver_key, ReusableResolverType.TIMEZONE);
                return this.resolveTimezone(lookupResolver);
            }
            case ResolverType.ANSWER_VALUE: {
                const page = this.resolvePage(resolver.page_resolver);
                const resolved = this.resolveAnswer(resolver.block_key, page, resolver === null || resolver === void 0 ? void 0 : resolver.answer_key, {}, ValuesType.TIMEZONE);
                return resolved;
            }
            case CachedQueryLatestSourcedDataType.TIME_ZONE_VALUE: {
                const fallback = this.resolveTimezone(resolver.if_none);
                return this.resolveLatestSourcedData(resolver.value_key, fallback, ValuesType.TIMEZONE);
            }
            case ResolverType.TIMEZONE: {
                return resolver.value;
            }
            case ResolverType.TIMEZONE_ANSWER: {
                const resolved = this.resolveAnswer(resolver.block_key, resolver.page_key, resolver.answer_key, {}, ValuesType.TIMEZONE);
                return resolved;
            }
            case ResolverType.TIMEZONE_CRASH: {
                this.resolveCrash(resolver.message);
                break;
            }
            case ResolverType.TIMEZONE_DEVICE: {
                return timeZoneName;
            }
            default: {
                this.resolveCrash(`${type} is an unsupported timezone resolver`);
            }
        }
    }
    resolveZonedDateTime(resolver) {
        var _a;
        if (!resolver)
            return '';
        const { type } = resolver;
        switch (type) {
            case ResolverType.LOOKUP: {
                const lookupResolver = this.resolveResolvers(resolver.resolver_key, ReusableResolverType.ZONED_DATETIME);
                return this.resolveZonedDateTime(lookupResolver);
            }
            case ResolverType.GET_COMPUTED_VALUE: {
                return this.resolveComputedAnswer(resolver);
            }
            case ResolverType.CCQ_MANY_GET_CURRENT_ITERATION_VALUE: {
                return this.resolveCCQManyGetCurrentIterationAnswer(resolver);
            }
            case ResolverType.CCQ_MANY_GET_MATCHING_ENTRY: {
                const value = this.resolveCCQManyGetMatchingValue(resolver);
                if (typeof value !== 'string')
                    this.resolveCrash('CCQ Get Many Matching Entry - Type Mismatch');
                return value;
            }
            case ResolverType.CCQ_SINGLE_GET_VALUE: {
                return this.resolveCCQSingleAnswer(resolver, ValuesType.ZONED_DATETIME);
            }
            case ResolverType.ANSWER_VALUE: {
                const page = this.resolvePage(resolver.page_resolver);
                const resolved = this.resolveAnswer(resolver.block_key, page, resolver === null || resolver === void 0 ? void 0 : resolver.answer_key, {}, ValuesType.ZONED_DATETIME);
                return resolved;
            }
            case CachedQueryLatestSourcedDataType.ZONED_DATE_TIME_VALUE: {
                const fallback = this.resolveZonedDateTime(resolver.if_none);
                return this.resolveLatestSourcedData(resolver.value_key, fallback, ValuesType.ZONED_DATETIME);
            }
            case ResolverType.ZONED_DATETIME: {
                return resolver.value;
            }
            case ResolverType.ZONED_DATETIME_ANSWER: {
                const resolved = this.resolveAnswer(resolver.block_key, resolver.page_key, resolver.answer_key, {}, ValuesType.ZONED_DATETIME);
                return resolved;
            }
            case ResolverType.ZONED_DATETIME_CRASH: {
                this.resolveCrash(resolver.message);
                break;
            }
            case ResolverType.ZONED_DATETIME_CURRENT: {
                return this.formatDate().tz_offset;
            }
            case ResolverType.ZONED_DATETIME_MODIFICATION: {
                const { type, value } = resolver, units = __rest(resolver, ["type", "value"]);
                if (!this.resolveZonedDateTime(value)) {
                    return this.formatDate().tz_offset;
                }
                const timeUnits = this.resolveTimeUnits(units);
                const date = new Date(this.resolveZonedDateTime(value));
                const dateModified = this.formatDateModified(date, timeUnits);
                return this.formatDate(dateModified).tz_offset;
            }
            case ResolverType.ZONED_DATETIME_START_OF_DAY: {
                const date = new Date(this.resolveZonedDateTime(resolver.value));
                const dateModified = this.resolveScheduleDate(date).START_OF_DAY;
                return this.formatDate(dateModified).tz_offset;
            }
            case ResolverType.ZONED_DATETIME_SWITCH: {
                const condition = resolver.cases.find((c) => this.resolveBoolean(c.condition));
                return this.resolveZonedDateTime((_a = condition === null || condition === void 0 ? void 0 : condition.value) !== null && _a !== void 0 ? _a : resolver.fallback);
            }
            case ResolverType.ZONED_DATETIME_FALLBACK: {
                return this.resolveNullFallbackValue(resolver);
            }
            default: {
                this.resolveCrash(`${type} is an unsupported ZonedDateTime resolver`);
            }
        }
    }
    get getAnswersCachedMap() {
        return getMapByKey(__classPrivateFieldGet(this, _ResolveValue_cachedAnswers, "f").normal, 'key');
    }
}
_ResolveValue_answers = new WeakMap(), _ResolveValue_cachedAnswers = new WeakMap(), _ResolveValue_segment = new WeakMap(), _ResolveValue_reusableResolvers = new WeakMap(), _ResolveValue_computedValues = new WeakMap();
