var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Flex, HStack, Heading, Text, VStack } from '@chakra-ui/react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { v4 as uuid_v4 } from 'uuid';
import { BlockStyle, ConfigBlockListItem, ConfigInlineHeader, ConfigSchedule, Image, } from '@/components';
import { usePolling } from '@/hooks';
import { SchedulingService } from '@/services';
import { setUserPhase, useAuth, useConfig } from '@/store';
import { APP_CONFIG } from '@/types';
const { ConfigBlockType, ConfigComponentType } = APP_CONFIG.COMPONENTS;
export const Dashboard = () => {
    const { t } = useTranslation();
    const { getComponentConfig } = useConfig();
    const { dispatch, state: { currentUserPhase }, } = useAuth();
    const pollingCallback = () => __awaiter(void 0, void 0, void 0, function* () {
        const phase = yield SchedulingService.getCurrentUserPhaseAndEvents();
        dispatch(setUserPhase(phase));
        return phase;
    });
    usePolling(pollingCallback);
    const dashboard = getComponentConfig(ConfigComponentType.DASHBOARD, currentUserPhase);
    const dashboardWeb = getComponentConfig(ConfigComponentType.WEB_DASHBOARD);
    const blocks = useMemo(() => {
        const findConfigBlock = (type) => {
            return dashboard === null || dashboard === void 0 ? void 0 : dashboard.blocks.find((b) => b.type === type);
        };
        const list = findConfigBlock(ConfigBlockType.LIST);
        const header = findConfigBlock(ConfigBlockType.HEADER);
        const logo = findConfigBlock(ConfigBlockType.IMAGE);
        const information = findConfigBlock(ConfigBlockType.INFORMATION);
        const schedule = findConfigBlock(ConfigBlockType.SCHEDULE);
        return {
            information,
            list: {
                header,
                items: list === null || list === void 0 ? void 0 : list.items.filter((x) => !x.phases || x.phases.includes(currentUserPhase)),
            },
            logo,
            schedule,
        };
    }, [dashboard, currentUserPhase]);
    const StudyLogo = () => {
        if (blocks.logo) {
            return _jsx(Image, { alt: "logo", image_id: blocks.logo.image_id, maxH: "125px", mb: "20px" });
        }
        return null;
    };
    const Information = () => {
        var _a;
        if (!blocks.information)
            return null;
        return (_jsx(BlockStyle, Object.assign({ fontSize: "md", p: "4", style_id: (_a = blocks.information) === null || _a === void 0 ? void 0 : _a.style_id, w: "100%" }, { children: _jsx(Text, { children: t(blocks.information.text) }) })));
    };
    const ListItems = () => {
        var _a;
        return (((_a = blocks.list.items) === null || _a === void 0 ? void 0 : _a.length) > 0 && (_jsxs(VStack, Object.assign({ alignItems: "flex-start", w: "100%" }, { children: [_jsx(ConfigInlineHeader, Object.assign({}, blocks.list.header)), blocks.list.items.map((item) => {
                    return _jsx(ConfigBlockListItem, Object.assign({}, item), item.destination_id);
                })] }))));
    };
    const Tiles = () => {
        const tiles = dashboardWeb === null || dashboardWeb === void 0 ? void 0 : dashboardWeb.items.find((item) => item.type === 'tile_grid_block').items.map((item) => (Object.assign(Object.assign({}, item), { id: uuid_v4() }))).filter((tile) => !tile.phase_uuid || tile.phase_uuid.includes(currentUserPhase));
        if ((tiles === null || tiles === void 0 ? void 0 : tiles.length) > 0) {
            return tiles.map(({ icon, id, label, request }) => {
                return (['url:path', 'url:external'].includes(request.type) && (_jsxs(VStack, Object.assign({ alignItems: "center", gridGap: "0.5", justifyContent: "center" }, { children: [_jsx(Link, Object.assign({ "data-test": id }, (request.type === 'url:path'
                            ? {
                                to: request.path,
                            }
                            : {
                                rel: 'noreferrer',
                                target: '_blank',
                                to: { pathname: request.url },
                            }), { children: _jsx(HStack, Object.assign({ bg: "darkBrandBlue.5", borderRadius: "16", h: "125px", justifyContent: "center", w: "173px" }, { children: _jsx(Image, { alt: label, path: icon }) })) }), id), _jsx(Text, Object.assign({ fontSize: "large" }, { children: t(label) }))] }))));
            });
        }
        return null;
    };
    if (!dashboard)
        return _jsx(_Fragment, {});
    return (_jsxs(VStack, { children: [_jsxs(Flex, Object.assign({ flexDirection: "column", gridGap: "12", maxW: "820px", px: "4", py: "16", w: "100%" }, { children: [_jsxs(VStack, Object.assign({ spacing: "4" }, { children: [_jsx(StudyLogo, {}), _jsx(Heading, Object.assign({ as: "h1", fontSize: "2xl", textAlign: "center" }, { children: t(dashboard === null || dashboard === void 0 ? void 0 : dashboard.title) })), _jsx(Information, {})] })), _jsxs(VStack, Object.assign({ spacing: "6", w: "100%" }, { children: [_jsx(ConfigSchedule, {}), _jsx(ListItems, {})] }))] })), _jsx(Flex, Object.assign({ gridGap: "8", maxW: "820px", pb: "16", px: "4", textAlign: "center", wrap: "wrap" }, { children: Tiles() }))] }));
};
