import { addDays, addHours, addMinutes, addMonths, addSeconds, addWeeks, addYears, endOfDay, endOfMonth, endOfWeek, format, startOfDay, startOfMonth, startOfWeek, } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { APP_CONFIG, DatetimeGranularityType } from '@/types';
import { DateTimeFormatterType } from '@/types/questionnaire/evaluators/dateTimeFormatterResolver';
import { is12Hour, timeZoneName } from '@/utils';
export class ResolveDate {
    constructor() {
        this.formatDateToMidnight = (date) => {
            const dateObj = new Date(date);
            return startOfDay(dateObj);
        };
    }
    formatDate(date = new Date(), tzName = timeZoneName) {
        // Format the date in the provided timezone
        const tz_offset = formatInTimeZone(date, tzName, `yyyy-MM-dd'T'HH:mm:ssxxx`);
        // Always outputs the date and time in UTC, represented by the Z timezone offset.
        const utc = date.toISOString(); // Output: "2024-05-15T12:34:56.000Z"
        return {
            date: format(date, this.formatterType.date_stamp),
            tz_offset,
            utc,
        };
    }
    removeTimezone(dateString) {
        // This regex captures the date and time parts before the timezone offset
        const match = dateString.match(/(.*?)([+-]\d{2}:\d{2})?$/);
        if (match) {
            const dateTimeWithoutTimezone = match[1]; // The part without the timezone
            return dateTimeWithoutTimezone;
        }
        return dateString; // Return the original if it doesn't match the expected format
    }
    formatDateLocalized(date = new Date()) {
        const formatter = (f) => format(date, f);
        return {
            [DateTimeFormatterType.DATE]: formatter(this.formatterType.date),
            [DateTimeFormatterType.DATE_STAMP]: formatter(this.formatterType.date_stamp),
            [DateTimeFormatterType.DATE_TIME]: formatter(this.formatterType.date_time),
            [DateTimeFormatterType.TIME]: formatter(this.formatterType.time),
            [DatetimeGranularityType.DAY]: formatter(this.formatterType.D),
            [DatetimeGranularityType.MONTH]: formatter(this.formatterType.M),
            [DatetimeGranularityType.TIME]: formatter(this.formatterType.T),
            [DatetimeGranularityType.YEAR]: formatter(this.formatterType.Y),
        };
    }
    formatDateModified(date, timeUnits) {
        const timeMap = {
            days: (val) => addDays(date, val),
            hours: (val) => addHours(date, val),
            minutes: (val) => addMinutes(date, val),
            months: (val) => addMonths(date, val),
            seconds: (val) => addSeconds(date, val),
            weeks: (val) => addWeeks(date, val),
            years: (val) => addYears(date, val),
        };
        for (const [key, value] of Object.entries(timeUnits)) {
            const timeUnit = key;
            date = timeMap[timeUnit](value);
        }
        return date;
    }
    resolveScheduleDate(date = new Date()) {
        const { ScheduleDateType } = APP_CONFIG.COMPONENTS;
        const maxDate = 8640000000000000;
        return {
            [ScheduleDateType.END_OF_CALENDAR_MONTH]: endOfMonth(date),
            [ScheduleDateType.END_OF_CALENDAR_WEEK]: endOfWeek(date),
            [ScheduleDateType.END_OF_DAY]: endOfDay(date),
            [ScheduleDateType.END_OF_PHASE]: new Date(maxDate),
            [ScheduleDateType.ONE_MONTH_END_OF_DAY]: endOfDay(addMonths(date, 1)),
            [ScheduleDateType.ONE_WEEK_END_OF_DAY]: endOfDay(addDays(date, 6)),
            [ScheduleDateType.ONE_YEAR_END_OF_DAY]: endOfDay(addYears(date, 1)),
            [ScheduleDateType.START_OF_CALENDAR_MONTH]: startOfMonth(date),
            [ScheduleDateType.START_OF_CALENDAR_WEEK]: startOfWeek(date),
            [ScheduleDateType.START_OF_DAY]: startOfDay(date),
            [ScheduleDateType.START_OF_PHASE]: new Date(-maxDate),
        };
    }
    get formatterType() {
        const date = 'PP';
        const time = is12Hour ? 'hh:mm a' : 'HH:mm';
        const d = 'dd'; // 01
        const m = 'MM'; // 01
        const mName = 'MMM'; // Jan
        const y = 'yyyy'; // 2021
        const dateTime = `${date}, ${time}`;
        const dateStamp = `${y}-${m}-${d}`;
        return {
            [DateTimeFormatterType.DATE]: date,
            [DateTimeFormatterType.DATE_STAMP]: dateStamp,
            [DateTimeFormatterType.DATE_TIME]: dateTime,
            [DateTimeFormatterType.TIME]: time,
            [DatetimeGranularityType.DAY]: date,
            [DatetimeGranularityType.MONTH]: `${mName} ${y}`,
            [DatetimeGranularityType.TIME]: dateTime,
            [DatetimeGranularityType.YEAR]: y,
        };
    }
}
