var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import axios from 'axios';
import i18n from 'i18next';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToast } from '@/hooks';
import { useAuth } from '@/store';
import { HTTP_STATUS, HeadersRequestKey } from '@/types';
import { ResolveDate, getLocalStorage } from '@/utils';
import { AuthService } from '.';
export const instance = axios.create();
instance.interceptors.request.use((config) => {
    const resolveDate = new ResolveDate();
    const { access_token, api_url } = getLocalStorage();
    config.baseURL = api_url;
    // SET HEADERS
    config.headers = Object.assign(Object.assign({}, config.headers), { 'Accept-Language': i18n.language, Authorization: `Bearer ${access_token}`, 'Content-Type': 'application/json', [HeadersRequestKey.DATETIME]: resolveDate.formatDate().tz_offset });
    return config;
}, (err) => Promise.reject(err));
export const AxiosInterceptor = ({ children }) => {
    const [isReady, setIsReady] = useState(false);
    const { t } = useTranslation();
    const { showToast } = useToast();
    const { handleLogout, state: { user }, } = useAuth();
    let totalRetry = 0;
    const handleUnauthorizedError = (apiError) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        const { config, data } = apiError.response;
        if ((_a = config === null || config === void 0 ? void 0 : config.data) === null || _a === void 0 ? void 0 : _a.includes('password')) {
            showToast({ message: data.message });
        }
        else {
            if (totalRetry === 0) {
                totalRetry += 1;
                yield AuthService.refreshToken();
                return instance.request(config);
            }
            handleLogout();
        }
        return Promise.reject(apiError.response);
    });
    const handleErrorMessage = (apiError) => {
        const { config, response: { data, status }, } = apiError;
        const getErrorMessage = () => {
            var _a;
            const defaultMessage = t('common:message.error.default');
            if (status >= HTTP_STATUS.INTERNAL_SERVER_ERROR) {
                return defaultMessage;
            }
            else {
                const endpointErrors = [
                    {
                        messages: {
                            [HTTP_STATUS.NOT_FOUND]: t('common:message.error.invalidCode'),
                            [HTTP_STATUS.UNPROCESSABLE_ENTITY]: '',
                        },
                        url: '/api/auth/invitation', // to be fixed under TMP-5131
                    },
                ];
                const error = endpointErrors.find((x) => config.url.startsWith(x.url) && x.messages.hasOwnProperty(status));
                if (error)
                    return error.messages[status];
                return ((_a = data === null || data === void 0 ? void 0 : data.errors) === null || _a === void 0 ? void 0 : _a.map((x) => x === null || x === void 0 ? void 0 : x.detail)) || defaultMessage;
            }
        };
        const whiteList = ['get-partials', 'post-partials', 'start-upload'];
        const skipError = whiteList.some((x) => config.url.includes(x));
        if (skipError) {
            return Promise.resolve(apiError.response);
        }
        else {
            const message = getErrorMessage();
            if (message)
                showToast({ message });
            return Promise.reject(apiError.response);
        }
    };
    useEffect(() => {
        const initialiseInterceptors = () => {
            try {
                const responseInterceptor = (response) => response;
                const responseErrorInterceptor = (err) => {
                    const { status } = err.response;
                    switch (status) {
                        case HTTP_STATUS.UNAUTHORIZED:
                            return handleUnauthorizedError(err);
                        default:
                            return handleErrorMessage(err);
                    }
                };
                const requestInterceptor = (request) => {
                    var _a, _b;
                    const locale = ((_b = (_a = user.data) === null || _a === void 0 ? void 0 : _a.attributes) === null || _b === void 0 ? void 0 : _b.locale) || i18n.language;
                    request.headers[HeadersRequestKey.LOCALE] = locale;
                    return request;
                };
                const requestErrorInterceptor = (err) => Promise.reject(err);
                const interceptRequest = instance.interceptors.request.use(requestInterceptor, requestErrorInterceptor);
                const interceptResponse = instance.interceptors.response.use(responseInterceptor, responseErrorInterceptor);
                return () => {
                    instance.interceptors.request.eject(interceptRequest);
                    instance.interceptors.response.eject(interceptResponse);
                };
            }
            finally {
                setIsReady(true);
            }
        };
        initialiseInterceptors();
    }, []);
    if (!isReady) {
        return null;
    }
    return children;
};
export default instance;
